export interface ShippingFormState {
  value: string
  touched: boolean
  blured: boolean
  valid: boolean
  api_error_message?: string
}

interface CountryCode extends ShippingFormState {
  value: string
}

const defaultVal: ShippingFormState = {
  value: '',
  touched: false,
  blured: false,
  valid: false
}

interface ShippingModel {
  recipient_name: ShippingFormState
  line_1: ShippingFormState
  line2: ShippingFormState
  city: ShippingFormState
  postal_code: ShippingFormState
  country_code: CountryCode
  state: ShippingFormState
  phone: ShippingFormState
  store: ShippingFormState
  email: ShippingFormState
  note: ShippingFormState
  tableNumber: ShippingFormState
}

interface ShippingModelProps {
  recipient_name?: ShippingFormState
  line_1?: ShippingFormState
  line2?: ShippingFormState
  city?: ShippingFormState
  postal_code?: ShippingFormState
  country_code?: CountryCode
  state?: ShippingFormState
  phone?: ShippingFormState
  store?: ShippingFormState
  email?: ShippingFormState
  note?: ShippingFormState
  tableNumber?: ShippingFormState

}

class ShippingModel {
  constructor({
    recipient_name = defaultVal,
    line_1 = defaultVal,
    line2 = defaultVal,
    city = defaultVal,
    postal_code = defaultVal,
    country_code = { ...defaultVal, value: 'US' },
    state = defaultVal,
    phone = defaultVal,
    store = defaultVal,
    email = defaultVal,
    note = defaultVal,
    tableNumber = defaultVal
  }: ShippingModelProps = {}) {
    this.recipient_name = recipient_name
    this.line_1 = line_1
    this.line2 = line2
    this.city = city
    this.postal_code = postal_code
    this.country_code = country_code
    this.state = state
    this.phone = phone
    this.store = store
    this.email = email
    this.note = note
    this.tableNumber = tableNumber
  }

  setTouched() {
    this.recipient_name.touched = true
    this.line_1.touched = true
    this.line2.touched = true
    this.city.touched = true
    this.postal_code.touched = true
    this.country_code.touched = true
    this.state.touched = true
    this.phone.touched = true
    this.store.touched = true
    this.email.touched = true
    this.note.touched = true
    this.tableNumber.touched = true
  }

  getFlatObject() {
    return {
      recipient_name: this.recipient_name.value,
      line_1: this.line_1.value,
      line2: this.line2.value,
      city: this.city.value,
      postal_code: this.postal_code.value,
      country_code: this.country_code.value,
      state: this.state.value,
      phone: this.phone.value,
      store: this.store.value,
      email: this.email.value,
      note: this.note.value,
      tableNumber: this.tableNumber.value
    }
  }
}

export default ShippingModel
