import produce from 'immer'
import {
  FETCH_CLAIM_CODES_FAILED,
  FETCH_CLAIM_CODES_INPROGRESS,
  FETCH_CLAIM_CODES_SUCCESS,
  MARK_CODE_AS_UNUSED,
  MARK_CODE_AS_USED,
  SET_ACTIVE_CODE,
  SET_CLAIM_CODES,
  SET_QR_CODE
} from '../../actions/const'

export const initialClaimCodes = {
  codes: [],
  status: null, // SUCCESS | IN_PROGRESS | ERROR
  statusDescription: null
}

export default produce((draft, action) => {
  switch (action.type) {
    case SET_CLAIM_CODES:
      draft.codes = action.codes.map(code => ({
        ...code,
        is_used: false,
        is_active: false
      }))
      break

    case FETCH_CLAIM_CODES_SUCCESS:
      draft.status = 'SUCCESS'
      break

    case FETCH_CLAIM_CODES_INPROGRESS:
      draft.status = 'IN_PROGRESS'
      break

    case FETCH_CLAIM_CODES_FAILED:
      draft.status = 'ERROR'
      draft.statusDescription = action.reason
      break

    case SET_ACTIVE_CODE: {
      try {
        let codes = draft.codes.filter(code => code.get('is_claimed') === false)
        const newCode =
          // get one from url param
          (action.codeProposal &&
            codes.find(item => item.get('short_id') === action.codeProposal)) ||
          // otherwise get one first not used and exlcuded
          codes.find(
            item =>
              item.get('is_used') === false &&
              item.get('short_id') !== action.codeExcluded
          ) ||
          // otherwise get first after active one
          codes.reduce((acc, item, index) =>
            item.get('is_active') === true
              ? codes.get(index + 1) || codes.get(1)
              : acc
          )
        const index = codes.findIndex(
          code => code.get('short_id') === newCode.get('short_id')
        )
        codes = codes
          .map(code => code.set('is_active', false))
          .setIn([index, 'is_active'], true)
        draft.codes = codes
        break
      } catch (e) {
        console.error(e)
        return draft
      }
    }

    case SET_QR_CODE: {
      try {
        const indexOfListingToUpdate = draft.codes.findIndex(listing => {
          return listing.get('short_id') === action.forCode
        })
        return draft.setIn(
          ['codes', indexOfListingToUpdate, 'qr_code_image'],
          action.image
        )
      } catch (e) {
        console.error(e)
        return draft
      }
    }

    case MARK_CODE_AS_USED: {
      try {
        const index = draft.codes
          .findIndex(code => code.get('short_id') === action.code)
        return draft.setIn(['codes', index, 'is_used'], true)
      } catch (e) {
        console.error(e)
        return draft
      }
    }
    case MARK_CODE_AS_UNUSED: {
      try {
        const index = draft.codes
          .findIndex(code => code.get('short_id') === action.code)
        return draft.setIn(['codes', index, 'is_used'], false)
      } catch (e) {
        console.error(e)
        return draft
      }
    }

    default:
      return draft
  }
}, initialClaimCodes)
