import mockImagesWithID from '../../helpers/mockImagesWithID'
import isArray from 'lodash/isArray'
import SubOption from './SubOption'
import { b64EncodeUnicode } from '../../helpers/base64'
import { sum, toNumber, keyBy, flatten, countBy } from 'lodash'

/* global FB */

interface Edition {
  name: string,
  total: number
}

type DimUnit = 'cm' | 'in'

interface Tag {
  name: string,
  sortOrder?: number
}

type ProductSubOptionItem = {
  _id: string,
  name: string,
  price: number,
  productSubOptionId: string
}

export type ProductSubOption = {
  items: ProductSubOptionItem[]
}

interface Product {
  _id: string,
  name: string,
  price: number,
  vat_percent: number,
  is_stock_enabled: boolean,
  stock_quantity: number,
  is_shipping_enabled: boolean,
  description: string,
  createdAt: string,
  count: number,
  is_indexable: boolean,
  is_private: boolean,
  d_width: number,
  d_height: number,
  d_depth: number,
  d_unit: DimUnit | null,
  weight: number,
  art_medium: null | { name: string },
  art_style: null | { name: string },
  is_artwork: boolean,
  weight_unit: string,
  is_wholesale: boolean,
  additional_information: string,
  is_pickup_allowed: boolean,
  tax_percentage: number,
  images: string[],
  thumbnails: string[],
  edition: null | Edition,
  pickup_information: string,
  sub_options: SubOption[],
  chosen_sub_options: string[][],
  product_sub_options: ProductSubOption[] // populated for checkout
  tag: Tag | null,
  comments: string
}
interface ProductParams {
  _id?: string,
  name?: string,
  price?: number,
  vat_percent?: number,
  is_stock_enabled?: boolean,
  stock_quantity?: number,
  is_shipping_enabled?: boolean,
  description?: string,
  createdAt?: string,
  count?: number,
  is_indexable?: boolean,
  is_private?: boolean,
  d_width?: number,
  d_height?: number,
  d_depth?: number,
  d_unit?: DimUnit | null,
  weight?: number,
  art_medium?: null | { name: string },
  art_style?: null | { name: string },
  is_artwork?: boolean,
  weight_unit?: string,
  is_wholesale?: boolean,
  additional_information?: string,
  is_pickup_allowed?: boolean,
  tax_percentage?: number,
  images?: string[],
  thumbnails?: string[],
  edition?: null | Edition,
  pickup_information?: string,
  sub_options?: SubOption[],
  chosen_sub_options?: string[][],
  tag?: Tag | null,
  comments?: string
}

class Product {
  constructor({
    _id = '',
    name = '',
    price = 0,
    vat_percent = 0,
    is_stock_enabled = false,
    stock_quantity = 0,
    is_shipping_enabled = false,
    description = '',
    createdAt = '',
    count = 0,
    is_indexable = false,
    is_private = false,
    d_width = 0,
    d_height = 0,
    d_depth = 0,
    d_unit = null,
    weight = 0,
    art_medium = null,
    art_style = null,
    is_artwork = false,
    weight_unit = '',
    is_wholesale = false,
    additional_information = '',
    is_pickup_allowed = false,
    tax_percentage = 0,
    images = [],
    thumbnails = [],
    edition = null,
    pickup_information = '',
    sub_options = [],
    chosen_sub_options = [],
    tag = null,
    comments = ''
  }: ProductParams = {}) {
    this._id = _id
    this.name = name
    this.price = price
    this.vat_percent = vat_percent
    this.is_stock_enabled = is_stock_enabled
    this.stock_quantity = stock_quantity
    this.is_shipping_enabled = is_shipping_enabled
    this.description = description
    this.createdAt = createdAt
    this.count = count
    this.is_indexable = is_indexable
    this.is_private = is_private
    this.d_width = d_width
    this.d_height = d_height
    this.d_depth = d_depth
    this.d_unit = d_unit
    this.weight = weight
    this.art_medium = art_medium
    this.art_style = art_style
    this.is_artwork = is_artwork
    this.weight_unit = weight_unit
    this.is_wholesale = is_wholesale
    this.additional_information = additional_information
    this.is_pickup_allowed = is_pickup_allowed
    this.tax_percentage = tax_percentage
    this.images = images
    this.thumbnails = thumbnails
    this.edition = edition
    this.pickup_information = pickup_information
    this.sub_options = sub_options
    this.chosen_sub_options = chosen_sub_options
    this.tag = tag
    this.comments = comments
  }

  getId() {
    return this._id
  }

  getName() {
    return this.name
  }

  getAdditionalInfo() {
    return this.additional_information
  }

  getArtMedium() {
    return this.art_medium && this.art_medium.name
  }

  getArtStyle() {
    return this.art_style && this.art_style.name
  }

  getIsArtwork() {
    return this.is_artwork ? 'Artwork' : false
  }

  getDWidth() {
    return this.d_width
  }

  getDHeight() {
    return this.d_height
  }

  getDdepth() {
    return this.d_depth
  }

  getDimensions() {
    const { d_width: x, d_height: y, d_depth: z, d_unit: unit } = this
    return x || y || z ? `${x || '0'} x ${y || '0'} x ${z || '0'} ${unit}` : ''
  }

  getWeight() {
    return this.weight
  }

  getWeightUnit() {
    return this.weight_unit
  }

  getPrice(): number {
    return this.price
  }

  getStock() {
    return this.stock_quantity
  }

  getDescription() {
    return this.description
  }

  getCount() {
    return this.count
  }

  isShippable() {
    return this.is_shipping_enabled || false
  }

  isPickupAllowed() {
    return this.is_pickup_allowed || false
  }

  isLimited() {
    return this.is_stock_enabled || false
  }

  getSum() {
    if (this.count) {
      const price = this.price * this.count
      return price + this.getSubOptionChoicesSum()
    } else return undefined
    // return this.count && this.count ? ((this.price * 100 * this.count) / 100).toFixed(2) : undefined
  }

  getTotSum() {
    if (this.count) {
      const tax = this.price * this.count * this.vat_percent
      const price = this.price * this.count + tax
      return price
    } else return undefined
    // return this.count && this.count ? ((this.price * 100 * this.count) / 100).toFixed(2) : undefined
  }

  getTaxPercentage() {
    return this.tax_percentage
  }

  getImageCount() {
    return this.images.length
  }

  isSoldOut() {
    return this.is_stock_enabled === true && this.getStock() === 0
  }

  isEnoughInStore(storeStock: number) {
    return storeStock > this.stock_quantity
  }

  isStockLeft() {
    return (
      this.is_stock_enabled &&
      this.stock_quantity < 4 &&
      this.stock_quantity !== 0
    )
  }

  getImages() {
    return this.images || []
  }

  getImage(): string | null {
    return this.images ? this.images[0] : null
  }

  getThumbnails() {
    return this.thumbnails || []
  }

  getThumbnail(): string | null {
    return this.thumbnails ? this.thumbnails[0] : this.getImage()
  }

  isEnabledStock() {
    return this.is_stock_enabled
  }

  setStock(newStock: number): void {
    this.stock_quantity = newStock
  }

  setStockEnabled(status: boolean): void {
    this.is_stock_enabled = status
  }

  setShippingEnabled(status: boolean): void {
    this.is_shipping_enabled = status
  }

  getFacebookPixelEventObject() {
    return {
      content_ids: this.getId(),
      content_name: this.getName(),
      content_type: 'product',
      currency: 'USD',
      value: this.getPrice()
    }
  }

  getFacebookEventObject() {
    return {
      [FB.AppEvents.ParameterNames.CONTENT_ID]: this.getId(),
      [FB.AppEvents.ParameterNames.CONTENT_TYPE]: 'product',
      [FB.AppEvents.ParameterNames.CURRENCY]: 'USD',
    }
  }

  getOwnUrl(storeId = '') {
    return (
      window.location.protocol +
      '//' +
      window.location.host +
      `/menu/${storeId}/product/${this._id}`
    )
  }

  getSocialPhoto(imageId: string = '') {
    const socialImages = mockImagesWithID(this.getImages())
    if (imageId && isArray(socialImages)) {
      const image = socialImages.find(
        image => image.image_id.toString() === imageId
      )
      return image ? image.url : socialImages[0]
    }
  }

  isPrivate() {
    return this.is_private
  }

  isIndexable() {
    return this.is_indexable
  }

  isWholesale() {
    return this.is_wholesale
  }

  getEdition() {
    return this.edition
  }

  hasSubOptions() {
    return this.sub_options.length > 0
  }

  getSubOptionChoices(): string[][] {
    return this.chosen_sub_options || []
  }

  getSubOptions(): SubOption[] {
    return this.sub_options
  }

  getSubOptionChoiceSum(itemNumber: number): number {
    const subOptions = keyBy(this.getSubOptions(), '_id')
    return sum(
      this.getSubOptionChoices()[itemNumber].map(
        (_id: string) => toNumber(subOptions[_id].sub_option.price)
      )
    )
  }

  getSubOptionChoiceText(itemNumber: number): string {
    const subOptions = keyBy(this.getSubOptions(), '_id')
    return this.getSubOptionChoices()[itemNumber].map((_id: string) => {
      return subOptions[_id].sub_option.name;
    }).join(', ')
  }

  getSubOptionChoicesSum(): number {
    const subOptions = keyBy(this.getSubOptions(), '_id')
    return sum(
      flatten(this.getSubOptionChoices()).map(
        (_id: string) => toNumber(subOptions[_id].sub_option.price)
      )
    )
  }

  getSubOptionChoicesText(): string {
    const subOptions = keyBy(this.getSubOptions(), '_id')
    const allChoices = flatten(this.getSubOptionChoices())
    const choices = countBy(allChoices, val => val)
    return Object.keys(choices).map((_id: string) => {
      const timesAdded = choices[_id];
      return `${timesAdded} x ${subOptions[_id].sub_option.name}`;
    }).join(', ')
  }

  populateCheckoutValues(): void {
    // Prepare values that are only needed for checkout

    const psos: ProductSubOption[] = []
    interface StringMap { [key: string]: ProductSubOptionItem; }
    const sos: StringMap = this.getSubOptions()
      .map((so: SubOption) => ({
        [so._id]: {
          _id: so._id,
          name: so.sub_option.name,
          productSubOptionId: b64EncodeUnicode(`EnterStoreProductSubOptionType:${so._id}`),
          price: so.sub_option.price
        }
      }))
      .reduce((dict, val) => ({ ...dict, ...val }), {})

    let pso: ProductSubOption
    for (const choices of this.getSubOptionChoices()) {
      let selectedItems: ProductSubOptionItem[] = choices.map((choice: string) => sos[choice])
      pso = { items: selectedItems };
      psos.push(pso)
    }
    this.product_sub_options = psos;
  }

  toggleSuboptionChoice(productIndex: number, subOption: SubOption) {
    const choices = this.getSubOptionChoices()
    const selectedForProduct = choices[productIndex]
    const idIndex = selectedForProduct.indexOf(subOption._id)
    if (idIndex < 0) {
      selectedForProduct.push(subOption._id)
    } else {
      selectedForProduct.splice(idIndex, 1)
    }
    return [
      ...choices.slice(0, productIndex),
      selectedForProduct,
      ...choices.slice(productIndex + 1)
    ]
  }
}

export default Product
