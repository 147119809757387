import styled from 'styled-components/macro'
import { media } from 'ui/rwd'
import { Button as BButton, Popover as BPopover } from 'reactstrap'
import Input from '../../shared/EnterStoreIdInput/EnterStoreIdInput'

const Wrapper = styled.div`
  user-select: none;
  width: 100%;
  ${media.sm`
    width: auto;
  `}
`

const Form = styled.form`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.sm`
    flex-direction: row;
    width: 400px;
  margin-left: 45px;
  `}

  ${media.lg`
    margin-top: 0;
    width: 450px;
    justify-content: flex-end;

  `}
`

const Button = styled(BButton)`
  &.btn {
    font-size: 1.4rem;
    line-height: 1.6;
    padding: 0.6rem 1.275rem;
    margin: 0.5em 0;
    width: 100%;
  }
  &:focus {
    box-shadow: none;
  }
`

const SubmitButton = styled(Button)`
  &.btn {
    height: 3rem;
    line-height: 1;
    ${media.sm`
      width: auto;
      margin-left: 15px;
    `}
  }
`

const EnterStoreIdInput = styled(Input)`
  &.form-control {
    margin: 0.5em 0;
    width: 100%;
    
    ${media.sm`
      width: auto;
    `}
  }
 
}


`

const Popover = styled(BPopover)`
  text-align: center;
  &.popover {
    z-index: 1049;
    a {
      cursor: pointer;
    }
  }
`

export { Wrapper, Form, Button, SubmitButton, EnterStoreIdInput, Popover }
