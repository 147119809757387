import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from './LangPickerStyledComponents'
import * as actionsLanguage from '../../../actions/language'

const defaultOptions = [
  {
    label: 'English',
    langCode: 'en'
  }
  // {
  //   label: 'Español',
  //   langCode: 'es'
  // }
  /*  {
    label: 'Íslensku',
    langCode: 'is'
  } */
]

const cookies = new Cookies()

class LangPicker extends PureComponent {
  static propTypes = {
    language: PropTypes.shape({
      langCode: PropTypes.string
    }),
    actionsLanguage: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      options: defaultOptions,
      currentLang: defaultOptions[0]
    }
    this.setStoreLang = this.setStoreLang.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount() {
    const currentLang = this.getStoreLang(this.props.language.langCode)
    this.setState({
      currentLang
    })
  }

  getStoreLang(langCode = 'en') {
    langCode = langCode === 'undefined' ? 'en' : langCode
    const lang = this.state.options.find(option => option.langCode === langCode)
    return lang
  }

  setStoreLang(language) {
    cookies.set('lang', language.langCode, { Path: '/' })
    this.props.actionsLanguage.set(language.langCode)
    this.setState({
      currentLang: language
    })
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  render() {
    const { options, currentLang, dropdownOpen } = this.state

    const optionsItems = options.map(option => (
      <DropdownItem
        key={option.langCode}
        selected={currentLang === option}
        className={'dropdown-' + option.langCode}
        onClick={() => {
          this.setStoreLang(option)
        }}
      >
        {option.label}
      </DropdownItem>
    ))
    return (
      <ButtonDropdown className="dropdown-toggle" isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret>{currentLang.label}</DropdownToggle>
        <DropdownMenu>{optionsItems}</DropdownMenu>
      </ButtonDropdown>
    )
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  }
}

const mapDispatchToProps = dispatch => ({
  actionsLanguage: bindActionCreators(actionsLanguage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LangPicker)
