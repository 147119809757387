import localStorageService from '../../api/services/localStorageService'
import ShippingModel from '../../api/models/ShippingModel'
import cloneDeep from 'lodash/cloneDeep'
import calculationReducer from './calculation'

const initialShippingDetails = localStorageService.getShippingDetails() || undefined

const initialState = {
  shippingForm: new ShippingModel(initialShippingDetails),
  paypalApiLoaded: false,
  paymentStatus: 'pending',
  orderData: {},
  missingProducts: [],
  checkoutErrors: [],
  paypalError: undefined,
  countriesList: [],
  shippingCost: 0,
  shippingMinDays: 0,
  shippingMaxDays: 0,
  countryStates: [],
  stateRequired: false,
  isPaypalPending: false,
  isPickupSelected: false,
  isTablePickupSelected: true,
  isServeToTableSelected: false,
  isShippingSelected: false,
  tableNumber: '',
  activeOrderId: null,
  calculation: null,
  curatorToken: null,
  serviceFee: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAYPAL_LOADED_STATE':
      return { ...state, paypalApiLoaded: action.loaded }
    case 'SET_PAYMENT_STATUS':
      return {
        ...state,
        paymentStatus: action.status,
        orderData: action.orderData,
        paypalError: action.paypalError
      }
    case 'SET_CHECKOUT_ERRORS':
      return {
        ...state,
        paymentStatus: action.status,
        checkoutErrors: action.checkoutErrors
      }
    case 'SET_PAYPAL_PENDING':
      return { ...state, isPaypalPending: action.status }
    case 'SET_CANCEL_INDICATOR':
      return { ...state, paymentCanceled: action.state }
    case 'SET_SHIPPING_COUNTRIES':
      return { ...state, countriesList: action.countriesList }
    case 'SET_CURATOR_TOKEN':
      return { ...state, curatorToken: action.curatorToken }
    case 'SET_CALCULATION':
      return {
        ...state,
        calculation: calculationReducer(state.calculation, {
          type: 'SET_CALCULATION',
          calculation: {
            shippingCost: action.shippingCost,
            subtotal: action.subtotal,
            tax: action.tax,
            totalAmount: action.totalAmount,
            serviceFee: action.serviceFee,
            convenienceFee: action.convenienceFee,
            currency: action.currency
          }
        })
      }
    case 'SET_SHIPPING_DELIVERY_TIME':
      return {
        ...state,
        shippingMinDays: action.shippingMinDays,
        shippingMaxDays: action.shippingMaxDays
      }
    case 'SET_STATES_FOR_COUNTRY':
      return {
        ...state,
        countryStates: action.countryStates,
        stateRequired: action.stateRequired
      }
    case 'HIGHLIGHT_INVALID': {
      const shippingForm = cloneDeep(state.shippingForm)
      for (const i in shippingForm) shippingForm[i].blured = true
      return { ...state, shippingForm }
    }
    case 'SET_INPUT_VALUE': {
      const shippingForm = cloneDeep(state.shippingForm)
      const newShippingForm = {
        ...shippingForm,
        [action.name]: {
          ...shippingForm[action.name],
          value: action.value,
          touched: true
        }
      }
      return { ...state, shippingForm: newShippingForm }
    }
    case 'SET_PICKUP_OPTION':
      return {
        ...state,
        isPickupSelected: true,
        isTablePickupSelected: false,
        isShippingSelected: false,
        isServeToTableSelected: false,
        tableNumber: ''
      }
    case 'SET_TABLE_PICKUP_OPTION':
      return {
        ...state,
        isPickupSelected: false,
        isTablePickupSelected: true,
        isShippingSelected: false,
        isServeToTableSelected: false,
        tableNumber: ''
      }
    case 'SET_SHIPPING_OPTION':
      return {
        ...state,
        isPickupSelected: false,
        isTablePickupSelected: false,
        isShippingSelected: true,
        isServeToTableSelected: false,
        tableNumber: ''
      }
    case 'SET_SERVE_TO_TABLE_OPTION':
      return {
        ...state,
        isPickupSelected: false,
        isTablePickupSelected: false,
        isShippingSelected: false,
        isServeToTableSelected: true
      }
    case 'SET_TABLE_NUMBER':
      return { ...state, tableNumber: action.tableNumber }
    case 'SET_SERVICE_FEE':
      return { ...state, serviceFee: action.serviceFee }
    case 'CLEAR_CHECKOUT_DATA':
      return { ...initialState }
    case 'SET_STORED_SHIPPING_DATA':
      return setStoredShippingData(state)
    case 'SET_FORM_VALIDATION':
      return setFormValidation(state, action)
    case 'SET_INPUT_BLUR':
      return setInputBlur(state, action)
    case 'SET_CHECKOUT_ORDER_ID':
      return { ...state, activeOrderId: action.id }
    case 'SET_MISSING_PRODUCTS':
      return { ...state, missingProducts: action.missingProducts }
    case 'SET_CALCULATION_API_ERROR':
      return {
        ...state,
        calculation: calculationReducer(state.calculation, {
          type: 'SET_CALCULATION_API_ERROR',
          errorMessage: action.errorMessage,
          errorField: action.errorField
        })
      }

    default:
      return state
  }
}

function setStoredShippingData (state) {
  const storedData = localStorageService.getShippingDetails()
  let initData
  if (storedData) {
    initData = {}
    Object.keys(storedData).forEach(key => {
      initData[key] = {
        ...storedData[key],
        blured: storedData[key].value !== '',
        touched: false,
        valid: key === 'state'
      }
    })
  }
  return { ...state, shippingForm: new ShippingModel(initData || undefined) }
}

function setFormValidation (state, action) {
  const sF = {}
  Object.keys(state.shippingForm).forEach(key => {
    sF[key] = { ...state.shippingForm[key], valid: true }
  })
  sF.store.valid = action.store
  sF.email.valid = action.email
  sF.phone.valid = action.phone
  sF.recipient_name.valid = action.recipient_name
  sF.country_code.valid = action.country_code
  sF.state.valid = !state.stateRequired || action.state
  sF.line_1.valid = action.line_1
  sF.city.valid = action.city
  sF.postal_code.valid = action.postal_code
  sF.tableNumber.valid = action.tableNumber

  if (action.postal_code_api_error) {
    sF.postal_code.api_error_message = action.postal_code_api_error
  } else {
    sF.postal_code.api_error_message = null
  }
  return { ...state, shippingForm: sF }
}

function setInputBlur (state, action) {
  const clonedShippingForm = cloneDeep(state.shippingForm)
  const newShippingForm = {
    ...clonedShippingForm,
    [action.name]: {
      ...clonedShippingForm[action.name],
      blured: true
    }
  }
  return { ...state, shippingForm: newShippingForm }
}
