import styled from 'styled-components/macro'
import { theme } from 'styled-tools'
import { media } from '../../../../ui/rwd'

const NavigationTileWrapper = styled.div`
  a {
    margin: 0.2em 1.5em;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: ${theme('black')};
  
    transition: color 0.3s;
    & svg {
      font-size: 2em;
      border-radius: 50%;
      padding: 5px;
    }
    :hover {
      & svg {
        background: ${theme('primary')};
        color: #fff;
      }
    }
    ${media.sm.down`
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid lightgrey;
      padding: 0.5em;
      :hover {
          background: ${theme('primary')};
          color: #fff;
          transform: none;
      }
  }
 
`}
`

const NavigationTileLabel = styled.span`
  font-size: 0.7em;
  text-align: center;
  margin-top: 0.3em;
`

export { NavigationTileWrapper, NavigationTileLabel }
