/* eslint-disable camelcase */
import * as React from 'react'
import { Helmet } from 'react-helmet'
import config from '../../../config'
import { useIntl } from 'react-intl'
import getCurrentUrl from '../../../helpers/getCurrentUrl'
import getHostUrl from '../../../helpers/getHostUrl'
import Product from 'api/models/Product'
import Store from 'api/models/Store'

interface RichResult {
  type: string
  product: Product
  productPath: string
  store: Store
  storePath: string
}

interface Props {
  title?: string
  description?: string
  keywords?: string
  theme_color?: string
  msapplication_navbutton_color?: string
  apple_mobile_web_app_status_bar_style?: string
  og_locale?: string
  og_title?: string
  og_description?: string
  og_type?: string
  og_url?: string
  og_image?: string
  og_image_secure_url?: string
  og_image_type?: string
  og_image_width?: string
  og_image_height?: string
  og_image_alt?: string
  og_site_name?: string
  fb_app_id?: string
  google_site_verification?: string
  product_price_amount?: string
  product_price_currency?: string
  defer?: boolean
  richResult?: RichResult
  noIndex?: boolean
}

export default function Metatags({
  title = '',
  description = '',
  keywords = '',
  theme_color = '',
  msapplication_navbutton_color = '',
  apple_mobile_web_app_status_bar_style = '',
  og_locale = '',
  og_title = '',
  og_description = '',
  og_type = '',
  og_url = '',
  og_image = '',
  og_image_secure_url = '',
  og_image_type = '',
  og_image_width = '',
  og_image_height = '',
  og_image_alt = '',
  og_site_name = '',
  fb_app_id = '',
  google_site_verification = '',
  product_price_amount = '',
  product_price_currency = '',
  defer = true,
  richResult,
  noIndex = false
}: Props) {
  const intl = useIntl()
  const getStoreMeta = () => {
    if (!richResult) {
      return ''
    }
    return `{"@context":"https://schema.org/","@type":"Organization","name":"${richResult.store.getName()}","description": ${JSON.stringify(
      richResult.store.getAboutText() || richResult.store.getDescription() || ''
    )},"image":"${richResult.store.getOwnerPicture()}","url":"${getCurrentUrl()}", "sameAS": [${richResult.store
      .getOwnerSocial()
      .map(social => '"' + social.url + '"')
      .join()}] }`
  }

  const getProductMeta = () => {
    if (!richResult) {
      return ''
    }
    return `{"@context":"https://schema.org/","@type":"Product","name":"${richResult.product.getName()}","image":"${richResult.product.getImage()}","description":"${richResult.product.getDescription()}","productID":"${richResult.product.getId()}","url":"${getCurrentUrl()}","offers":{"@type":"Offer","priceCurrency":"USD","price":"${richResult.product.getPrice()}"},  "sameAS": [${richResult.store
      .getOwnerSocial()
      .map(social => '"' + social.url + '"')
      .join()}] }`
  }

  const getBreadcrumbMetaForProduct = () => {
    if (!richResult) {
      return ''
    }
    return `{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement": [{"@type": "ListItem", "position": 1,"name": "hausmart.com","item": "${getHostUrl()}"},{"@type": "ListItem","position": 2,"name": "${richResult.store.getName()}","item": "${getHostUrl() +
      '' +
      richResult.storePath}"},{"@type": "ListItem","position": 3,"name": "${intl.formatMessage({
      id: 'general.products'
    })}","item": "${getHostUrl() +
      '' +
      richResult.storePath}"},{"@type": "ListItem","position": 4,"name": "${richResult.product.getName()}","item": "${getHostUrl() +
      '' +
      richResult.productPath}"}]}`
  }

  const getBreadcrumbMetaForStore = () => {
    if (!richResult) {
      return ''
    }
    return `{"@context":"https://schema.org/","@type":"BreadcrumbList","itemListElement": [{"@type": "ListItem","position": 1,"name": "Hausmart","item": "${getHostUrl()}"},{"@type": "ListItem", "position": 2,"name": "${richResult.store.getName()}","item": "${getHostUrl() +
      '' +
      richResult.storePath}"}]}`
  }

  return (
    <Helmet defer={defer}>
      <title>{title}</title>
      <meta name="description" content={description || intl.formatMessage({ id: 'metatags.description' })} />
      <meta name="keywords" content={keywords || intl.formatMessage({ id: 'metatags.keywords' })} />
      <meta name="theme-color" content={theme_color || '#222'} />
      <meta name="msapplication-navbutton-color" content={msapplication_navbutton_color || '#222'} />
      <meta name="apple-mobile-web-app-status-bar-style" content={apple_mobile_web_app_status_bar_style || '#222'} />
      <meta property="og:locale" content={og_locale || 'en_US'} />
      <meta property="og:type" content={og_type || 'website'} />
      <meta property="og:url" content={og_url || getCurrentUrl()} />
      <meta property="og:title" content={og_title || intl.formatMessage({ id: 'metatags.title' })} />
      <meta property="og:description" content={og_description || intl.formatMessage({ id: 'metatags.description' })} />
      <meta property="og:site_name" content={og_site_name || 'HausMart'} />
      <meta property="og:image" content={og_image || 'https://hausmart.com/images/ZT-sharing-img.png'} />
      <meta
        property="og:image:secure_url"
        content={og_image_secure_url || 'https://hausmart.com/images/ZT-sharing-img.png'}
      />
      <meta property="og:image:type" content={og_image_type || 'image/png'} />
      <meta property="og:image:width" content={og_image_width || '1200'} />
      <meta property="og:image:height" content={og_image_height || '630'} />
      <meta property="og:image:alt" content={og_image_alt || intl.formatMessage({ id: 'metatags.image.alt' })} />
      <meta property="fb:app_id" content={fb_app_id || '153212061969401'} />
      <meta property="product:price:amount" content={product_price_amount} />
      <meta property="product:price:currency" content={product_price_currency} />
      <meta name="google-site-verification" content={google_site_verification || config.googleSiteVerification} />
      <meta name="robots" content={noIndex ? 'noindex' : 'index'} />
      {richResult && richResult.type === 'Product' && <script type="application/ld+json">{getProductMeta()}</script>}
      {richResult && richResult.type === 'Product' && (
        <script type="application/ld+json">{getBreadcrumbMetaForProduct()}</script>
      )}
      {richResult && richResult.type === 'Store' && <script type="application/ld+json">{getStoreMeta()}</script>}
      {richResult && richResult.type === 'Store' && (
        <script type="application/ld+json">{getBreadcrumbMetaForStore()}</script>
      )}
    </Helmet>
  )
}

// let aqw = {
//   '@context': 'http://schema.org',
//   '@type': 'Organization',
//   'name': 'HausMart',
//   'url': 'https://hausmart.com',
//   'sameAs': [
//     'https://www.facebook.com/hausmartapp',
//     'https://www.instagram.com/hausmartapp',
//     'https://www.linkedin.com/company/hausmartapp/',
//     'https://twitter.com/hausmartapp',
//     'https://www.pinterest.com/hausmart0250'

//   ]
// }
