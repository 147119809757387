import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import ScrollToTop from '../../common/ScrollToTop'
import 'animate.css/animate.min.css'
import { Container, Content, OuterContainer } from './MailLayoutStyledComponents'
import { ThemeProvider } from 'styled-components/macro'
import { defaultTheme } from '../../../ui'
import { Footer } from '../../../pages/ShareCodes/ShareCodesStyledComponents'
import FaviconImage from '../../../assets/images/zerotouch_z.png'
import Favicon from 'react-favicon'

class MailLayout extends React.PureComponent {
  static propTypes = {
    animateFooter: PropTypes.bool,
    history: PropTypes.object,
    children: PropTypes.node
  }

  render() {
    const animateFooter = this.props.animateFooter === undefined ? true : this.props.animateFooter
    const { history, children } = this.props
    return (
      <ThemeProvider theme={defaultTheme}>
        <ScrollToTop>
          <Favicon url={FaviconImage} />
          <OuterContainer>
            <Container>
              <Row>
                <Col xs={{ size: '12' }} sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>
                  <Content>{React.cloneElement(children, this.props)}</Content>
                  <Footer animate={animateFooter} history={history} />
                </Col>
              </Row>
            </Container>
          </OuterContainer>
        </ScrollToTop>
      </ThemeProvider>
    )
  }
}

export default withRouter(MailLayout)
