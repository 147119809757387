import axios from 'axios'

function AxiosProvider (baseUrl) {
  const instance = axios.create({
    baseURL: baseUrl,
    headers: {
      'X-App-Source': 'ZEROTOUCH'
    }
  })

  return instance
}

export default AxiosProvider
