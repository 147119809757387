import React from 'react'
import { useIntl } from 'react-intl'
import { NavigationWrapper } from './NavigationStyledComponents'
import NavigationTile from './NavigationTile/NavigationTile'
import { MdHelpOutline, MdAssignment, MdMailOutline, MdPeopleOutline } from 'react-icons/md'

function Navigation() {
  const intl = useIntl()
  return (
    <NavigationWrapper>
      <NavigationTile Icon={MdHelpOutline} link="/faq" text={intl.formatMessage({ id: 'navigation.faq' })} />
      <NavigationTile
        Icon={MdMailOutline}
        link="https://chat.gozerotouch.com"
        text={intl.formatMessage({ id: 'navigation.contact' })}
      />
      <NavigationTile Icon={MdPeopleOutline} link="/about" text={intl.formatMessage({ id: 'navigation.aboutUs' })} />
      {/* <NavigationTile
        Icon={MdTrendingUp}
        link='/mission'
        text={intl.formatMessage({ id: 'navigation.ourMission' })}
      /> */}
      <NavigationTile Icon={MdAssignment} link="/terms" text={intl.formatMessage({ id: 'navigation.terms' })} />
      {/* <NavigationTile
        Icon={MdStoreMallDirectory}
        link="/store/DEMO1"
        text={intl.formatMessage({ id: 'navigation.demoShop' })}
      /> */}
      {/* <NavigationTile
        Icon={MdGrade}
        link='/testimonials'
        text={intl.formatMessage({ id: 'navigation.testimonials' })}
      /> */}
    </NavigationWrapper>
  )
}

Navigation.displayName = 'Navigation'

export default Navigation
