import React from 'react'
import { NavigationTileWrapper, NavigationTileLabel } from './NavigationTileStyledComponents'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const NavigationTile = ({ Icon, text, link }) => {
  if (link.includes('http')) {
    return (
      <NavigationTileWrapper>
        <a href={link}>
          <Icon />
          <NavigationTileLabel>{text}</NavigationTileLabel>
        </a>
      </NavigationTileWrapper>
    )
  }

  return (
    <NavigationTileWrapper>
      <Link to={link}>
        <Icon />
        <NavigationTileLabel>{text}</NavigationTileLabel>
      </Link>
    </NavigationTileWrapper>
  )
}

NavigationTile.displayName = 'NavigationTile'

NavigationTile.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

export default NavigationTile
