import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { matchPath } from 'react-router'
import preparedCartService from '../../api/services/preparedCartService'
import FilledCartProduct from '../../api/models/FilledCartProduct'

class RedirectProxy extends PureComponent {
  constructor (props) {
    super(props)
    this.matchAndResolve('/cart/:code', this.onMatchPreparedCart)
  }

  onMatchPreparedCart = (path, redirect) => {
    preparedCartService
      .getCart(this.props.match.params.code)
      .then(resp => {
        redirect({
          url: `/menu/${resp.store.short_id}`,
          state: {
            filledCart: resp.items.map(item => new FilledCartProduct(item))
          }
        })
      })
      .catch(resp => {
        redirect('/notFound')
      })
  }

  matchAndResolve = (path, successMatchCallback) => {
    const pathname = this.props.location.pathname
    if (matchPath(pathname, { path: path })) {
      new Promise((resolve, reject) => {
        successMatchCallback(pathname, resolve, reject)
      }).then((params, state) => {
        this.props.history.push({ pathname: params.url, state: params.state })
      })
    }
  }

  render () {
    return <div>loading...</div>
  }
}

RedirectProxy.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default RedirectProxy
