import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteWithLayout = ({ layout, component, tTitle, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        document.title = tTitle || 'ZeroTouch'
        return React.createElement(
          layout,
          Object.assign({ animateFooter: true }, rest, props),
          React.createElement(component, Object.assign({}, rest, props))
        )
      }}
    />
  )
}

RouteWithLayout.propTypes = {
  tTitle: PropTypes.string.isRequired,
  layout: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
}

export default withRouter(RouteWithLayout)
