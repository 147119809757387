import Store from '../api/models/Store'
import { setId } from './cart'

export interface SET_ACTIVE_STORE {
  type: 'SET_ACTIVE_STORE',
  store: Store
}
export interface CLEAR_ACTIVE_STORE {
  type: 'CLEAR_ACTIVE_STORE',
}
export interface SET_STOCK_ENABLED {
  type: 'SET_STOCK_ENABLED',
  status: boolean
}
export interface SET_PRODUCT_STOCK_ENABLED {
  type: 'SET_PRODUCT_STOCK_ENABLED',
  status: boolean,
  productId: string
}
export interface SET_SHIPPING_ENABLED {
  type: 'SET_SHIPPING_ENABLED',
  status: boolean
}
export interface SET_PRODUCT_SHIPPING_ENABLED {
  type: 'SET_PRODUCT_SHIPPING_ENABLED',
  productId: string,
  status: boolean
}

const setActiveStore = (store: Store) => {
  return function (dispatch: (obj: any) => void, getState: () => any) {
    const state = getState()
    if (state.cart.shortId !== store.shortId) {
      dispatch(setId(store.shortId, store.name))
    }
    dispatch({
      type: 'SET_ACTIVE_STORE',
      store
    })
  }
}

const clearActiveStore = () => {
  return {
    type: 'CLEAR_ACTIVE_STORE'
  }
}

const setStockEnabled = (status: boolean) => {
  return {
    type: 'SET_STOCK_ENABLED',
    status
  }
}

const setProductStockEnabled = (productId: string, status: boolean) => {
  return {
    type: 'SET_PRODUCT_STOCK_ENABLED',
    status,
    productId
  }
}

const setShippingEnabled = (status: boolean) => {
  return {
    type: 'SET_SHIPPING_ENABLED',
    status
  }
}

const setProductShippingEnabled = (productId: string, status: boolean) => {
  return {
    type: 'SET_PRODUCT_SHIPPING_ENABLED',
    productId,
    status
  }
}

export {
  setActiveStore,
  clearActiveStore,
  setStockEnabled,
  setProductStockEnabled,
  setProductShippingEnabled,
  setShippingEnabled
}
