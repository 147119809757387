import styled from 'styled-components/macro'
import { ifProp } from 'styled-tools'
import { media } from '../../../ui/rwd'

const Wrapper = styled.div`
  transition: 0.4s ease-in-out;
  height: auto;
  max-height: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // min-height: ${ifProp('contentProvided', '40vh', '0')};
`

const ContentItem = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/hausmartprod/image/upload/v1600420492/ZTmenu/heroImageBackground.0623eb1e.png');
  width: 100%;
  flex-grow: 1;
  min-height: 300px;
  > div > h1, h2,h3 {
    color: #fff;

  }
  > div > h1, h2 {
    max-width: 560px;
    margin: 0 auto;

    font-weight: 500;
    font-size: 3em;
  }
  ${media.sm.down`
    & * {
      font-size: 1.25em !important;
    }`}


`

const BanerContainer = styled.div`
  width: 100%;
`

export { Wrapper, ContentItem, BanerContainer }
