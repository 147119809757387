import isEmpty from 'lodash/isEmpty'
import Product from './Product'

interface Cart {
  shortId: string
  storeName: string
  products: Product[]
  currency: string
  tax_percentage?: number
  shippingCost: number
  getStoreId(): string
  getCartStoreName(): string
  getProducts(): Product[]
  getAvailableProducts(): Product[]
  hasShippingProduct(): boolean
  hasPickupProduct(): boolean
  isPickupAllowed(): boolean
  isAllProductsShippable(): boolean
  getPickupInfo(): string
  getSubtotal(): string
  getProduct(productId: string): Product | undefined
  onProductStockInsufficient(
    item: Product,
    forStockEnabledItems: boolean,
    onNoneAvailable: (item: Product, availableStock: number, requiredStock: number) => void,
    onFewAvailable: (item: Product, availableStock: number, requiredStock: number) => void
  ): void
  changeProductShippingEnabled(productId: string, status: boolean): void,
  isEmpty(): boolean
}

interface CartProps {
  shortId?: string
  storeName?: string
  products?: Product[]
  currency?: string
  tax_percentage?: number
  shippingCost?: number
}

class Cart {
  constructor({
    shortId = '',
    storeName = '',
    products = [],
    currency = '',
    tax_percentage = undefined,
    shippingCost = 0,
  }: CartProps = {}) {
    this.shortId = shortId
    this.storeName = storeName
    this.currency = currency
    this.tax_percentage = tax_percentage
    this.shippingCost = shippingCost
    //console.log('products',products instanceof Product)
    this.products = products.map(product => new Product({...product}))
    if (!shippingCost) this.shippingCost = 0
  }

  getStoreId(): string {
    return this.shortId || ''
  }

  getCartStoreName(): string {
    // in case when user visits same store after release
    return this.storeName || this.shortId || ''
  }

  getProducts(): Product[] {
    return this.products || []
  }

  prepareProductsForCheckout(): void {
    // Do the dirty work the web service
    this.products.forEach(product => product.populateCheckoutValues())
  }

  getAvailableProducts(): Product[] {
    const filtered = this.getProducts().filter(
      product =>
        !product.is_stock_enabled || product.stock_quantity > product.count
    )
    return filtered
  }

  hasShippingProduct(): boolean {
    return this.getProducts().some(item => item.isShippable())
  }

  hasPickupProduct(): boolean {
    return this.getProducts().some(item => item.isPickupAllowed())
  }

  isPickupAllowed(): boolean {
    return (
      !isEmpty(this.getProducts()) &&
      this.getProducts().every(item => item.isPickupAllowed())
    )
  }

  isAllProductsShippable(): boolean {
    return this.getProducts().every(item => item.isShippable())
  }

  getPickupInfo(): string {
    const pickupProduct = this.getProducts().find(item => item.isPickupAllowed())
    return pickupProduct ? pickupProduct.pickup_information : ''
  }

  getSubtotal(): string {
    return this.getProducts()
      .reduce(
        (accumulator, item) => {
          const prod = new Product({...item})
          const sum = prod.getSum();
          return sum ? accumulator + sum : accumulator
        },
        0
      )
      .toFixed(2)
  }

  getProduct(productId: string): Product | undefined {
    return this.getProducts().find(item => item._id === productId)
  }

  onProductStockInsufficient(
    item: Product,
    forStockEnabledItems: boolean = false,
    onNoneAvailable: (item: Product, availableStock: number, requiredStock: number) => void,
    onFewAvailable: (item: Product, availableStock: number, requiredStock: number) => void
  ): void {
    try {
      const id = item._id
      const productInCart = id ? this.getProduct(id) : null
      if (
        productInCart &&
        (forStockEnabledItems ? productInCart.is_stock_enabled : true)
      ) {
        const requiredStock = productInCart.getCount()
        const availableStock = item.stock_quantity
        if (!isEnoughInStock(requiredStock, availableStock)) {
          if (isMoreThanOneInStock(availableStock)) {
            onFewAvailable(item, availableStock, requiredStock)
          } else {
            onNoneAvailable(item, availableStock, requiredStock)
          }
        }
      }
    } catch (e) {
      console.error('error onProductStockInsufficient', e)
    }
    function isEnoughInStock(requiredValue: number, stockValue: number) {
      return stockValue >= requiredValue
    }

    function isMoreThanOneInStock(stockValue: number) {
      return stockValue > 0
    }
  }

  changeProductShippingEnabled(productId: string, status: boolean): void {
    const product = this.getProducts().find(item => item.getId() === productId)
    product && product.setShippingEnabled(status)
  }

  isEmpty(): boolean {
    return isEmpty(this.getProducts())
  }
}

export default Cart
