import React from 'react'
import hmlogo from '../../../assets/svg/Hausmart_logo_black.svg'
import ztlogo from '../../../assets/svg/ZeroTouch.png'
import { useHistory } from 'react-router-dom'
import { LogoImage } from './LogoStyledComponents'

interface Props {
  storeSource?: StoreSource
}

const Logo = React.memo(function Logo({ storeSource = 'ZEROTOUCH' }: Props) {
  const history = useHistory()
  const isZeroTouch = storeSource === 'ZEROTOUCH'

  return <LogoImage src={isZeroTouch ? ztlogo : hmlogo} onClick={() => history.push('/home')} alt={`ZeroTouch Logo`} />
})

Logo.displayName = 'Logo'

export default Logo
