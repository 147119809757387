import styled from 'styled-components/macro'
import posed from 'react-pose'
import { theme } from 'styled-tools'
import { CircleIcon as BaseCircleIcon } from '../../../ui/components/CircleIcons'
import { media } from '../../../ui/rwd'
import { Row } from 'reactstrap'
import { Link as BaseLink } from 'react-router-dom'
import UplandsImage from '../../../assets/images/uplands-bg.png'

const LinksRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3rem;
`

const BottomSection = styled.div`
  border-top: 1px solid ${theme('gray')};
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 25px 0 ;
`

const BottomRow = styled(Row)`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  ${media.sm.down`
    flex-direction: column;
  `}
`



const AnimatedContainer = posed.div({
  visible: {
    opacity: 1,
    y: 0,
    transition: ({ delay = 0 }) => ({
      type: 'spring',
      stiffness: 150,
      damping: 15,
      delay: 300 + delay
    })
  },

  hidden: {
    opacity: 0,
    y: 100
  }
})

const MainContainer = styled(AnimatedContainer)`
  background-color: #1f1c1d;
  width: 100%;
  font-size: 1.8rem;
`

const LinkFooter = styled(BaseLink)`
  display: block;
  text-transform: uppercase;
  font-size: 0.5em;
  color: ${theme('lightGray')};
  &:hover {
    color: white;
    text-decoration: underline;
  }
`

const LinkFooterExternal = styled.a`
  display: block;
  text-transform: uppercase;
  font-size: 0.5em;
  color: ${theme('lightGray')};
  &:hover {
    color: white;
    text-decoration: underline;
  }
`

const CircleIcon = styled(BaseCircleIcon).attrs({
  width: '2.5rem',
  height: '2.5rem'
})`
  cursor: pointer;
  background: ${theme('lightGray')};
  color: ${theme('black')};
  &:hover {
    background: white;
  }
`

const AppMobileIcon = styled.img`
  width: 144px;
  margin: 0.3rem;
`

const AppLink = styled.a`
  cursor: pointer;
`

const Divider = styled.div`
  width: 1px;
  height: 0.8rem;
  margin: 0 0.3rem;
  background: ${theme('lightGray')};
`


const Description = styled.span`
  max-width: 320px;
  color: ${theme('lightGray')};
  font-size: 0.4em;
  padding: 1rem 0;
`

const StoresLink = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 360px;
  ${media.sm`
    max-width: 320px;
  `}
`

const FooterPaddingImage = styled.div`
  background-image: url(${UplandsImage});
  background-position: top;
  background-repeat: repeat;
  background-size: cover;
  width: 100%;
  min-height: 12em;
  margin-top: 4em;
`

export {
  StoresLink,
  Description,

  Divider,
  AppLink,
  AppMobileIcon,
  CircleIcon,
  LinkFooter,
  MainContainer,
  LinksRow,
  BottomSection,
  BottomRow,
  LinkFooterExternal,
  FooterPaddingImage
}
