import React, { useState, useEffect, ReactElement } from 'react'
import CircleIconButton from '../../../ui/components/CircleIconButton'
import { MdArrowBack } from 'react-icons/md'
import { useHistory } from 'react-router'
import EnterStoreId from '../EnterStoreId/EnterStoreId'
import ztlogo from '../../../assets/svg/zeroTouchLogo.svg'
import Logo from '../Logo/Logo'
import { BigLogo } from './TopBarStyledComponents'
import {
  LeftCol,
  RightCol,
  EnterStoreBoxCol,
  MenuWrapper,
  TopBarRow,
  LogoCol,
  Wrapper,
  MenuIndicator
} from './TopBarStyledComponents'

import Navigation from '../Navigation/Navigation'
import { Notification } from 'react-notification-system'

interface Props {
  storeIdSection: boolean
  toggleGeolocationModal: () => void
  showNotification: (notification: Notification) => void
  backButton?: boolean
  backButtonAction?: () => void
  ActionButton?: () => ReactElement
  search: boolean
  opened: boolean
  storeSource?: StoreSource
  storeId?: string
}

export default function TopBar({
  storeIdSection = false,
  toggleGeolocationModal,
  showNotification,
  backButton,
  backButtonAction,
  ActionButton,
  search = true,
  storeSource = 'HAUSMART',
  storeId,
  opened
}: Props) {
  const history = useHistory()
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    setMenu(!!opened)
    if (opened) {
      setTimeout(() => {
        const searchInput = document.getElementById('search_bar-input')
        searchInput && searchInput.focus()
      }, 500)
    }
  }, [opened])

  const toggleMenu = (menu: boolean) => {
    setMenu(!menu)
    if (!menu) {
      setTimeout(() => {
        const searchBarInput = document.getElementById('search_bar-input')
        searchBarInput && searchBarInput.focus()
      }, 100)
    }
  }

  return (
    <>
      <Wrapper>
        {menu && (
          <MenuWrapper>
            <BigLogo src={ztlogo} onClick={() => history.push('/home')} alt={`ZeroTouch Logo`} />
            <Navigation />
          </MenuWrapper>
        )}
        {!menu && (
          <TopBarRow>
            <LeftCol xs={{ size: 2 }}>
              {backButton && (
                <CircleIconButton
                  onClick={() => {
                    if (backButtonAction) {
                      backButtonAction()
                    } else {
                      history.goBack()
                    }
                  }}
                >
                  <MdArrowBack />
                </CircleIconButton>
              )}
            </LeftCol>
            <LogoCol xs={{ size: 8 }} md={{ size: 12 }} lg={{ size: 3 }} xl={{ size: 2, offset: 1 }}>
              {storeId !== '' && <Logo />}
            </LogoCol>
            <RightCol xs={{ size: 2 }}>{ActionButton && <ActionButton />}</RightCol>
            {storeIdSection && (
              <EnterStoreBoxCol xs={{ size: '12' }} lg={{ size: 9 }} xl={{ size: 8 }}>
                <EnterStoreId
                  toggleGeolocationModal={toggleGeolocationModal}
                  history={history}
                  showNotification={showNotification}
                />
              </EnterStoreBoxCol>
            )}
          </TopBarRow>
        )}
      </Wrapper>
      {search && <MenuIndicator toggled={menu} onClick={() => toggleMenu(menu)} />}
    </>
  )
}
