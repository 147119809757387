import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

import config from '../config'

const cache = new InMemoryCache()
const link = new HttpLink({
  uri: config.graphqlBaseUrl,
  headers: {
    'X-App-Source': 'ZEROTOUCH'
  }
})

export const client = new ApolloClient({
  connectToDevTools: true,
  cache,
  link
})

cache.writeData({
  data: {
    requestCount: 0
  }
})

export default client
