import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

const DynamicBreadcrumbWrapper = styled.div`
  a {
    color: #767676;
    font-size: 0.7em;
  }
  b {
    font-size: 0.8em !important;
  }

  a:hover {
    color: ${theme('primary')};
    text-decoration: underline;
    font-size: 0.8em;
  }
`

export { DynamicBreadcrumbWrapper }
