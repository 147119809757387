import LangPicker from '../LangPicker/LangPicker';
import React from 'react';
import {
  BottomRow,
  BottomSection,
  Divider,
  FooterPaddingImage,
  LinkFooter,
  LinkFooterExternal,
  LinksRow,
  MainContainer
  } from './FooterStyledComponents';
import { Col, Container } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

interface Props {
  animate: boolean
  isZeroTouch: boolean
}

const Footer = React.memo(({ animate, isZeroTouch = false, ...rest }: Props) => {
  return (
    <>
      <FooterPaddingImage />

      <MainContainer delay={100} initialPose={'hidden'} pose={animate && 'visible'} {...rest}>
        <BottomSection>
          <Container>
            <BottomRow>
              <Col>
                <LinksRow>
                  <LinkFooter to="/faq">
                    <FormattedMessage id="footer.faq" />
                  </LinkFooter>

                  <Divider />
                  <LinkFooter to="/terms/1">
                    <FormattedMessage id="footer.terms" />
                  </LinkFooter>
                  <Divider />
                  <LinkFooter to="/terms/4">
                    <FormattedMessage id="footer.policy" />
                  </LinkFooter>
                  <Divider />

                  <LinkFooterExternal href={'https://chat.gozerotouch.com'}>
                    <FormattedMessage id="footer.contact" />
                  </LinkFooterExternal>
                </LinksRow>
              </Col>
              <LangPicker />
              
            </BottomRow>
          </Container>
        </BottomSection>
      </MainContainer>
    </>
  )
})

Footer.displayName = 'Footer'

export default Footer
