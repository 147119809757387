import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { isFirefox } from 'react-device-detect'
import { StyledInput } from './EnterStoreIdInputStyledComponents'

function EnterStoreIdInput({ value, onChange, onFocus, onKeyPress, className, maxLength, ...rest }) {
  const intl = useIntl()
  const [localValue, setLocalValue] = useState('')
  const [lastValue, setLastValue] = useState([])

  useEffect(() => {
    if (value) {
      setLocalValue(value)
    }
  }, [value])

  // FF MOBILE HACK
  const changeValue = event => {
    const element = event.target
    if (element.value.length <= maxLength) {
      setLocalValue(event.target.value)
      if (onChange) {
        onChange(event)
      }
    } else if (isFirefox) {
      setLocalValue(lastValue[lastValue.length - 2])
      setLastValue([...lastValue, lastValue[lastValue.length - 2], lastValue[lastValue.length - 2]])
      return
    }
    setLastValue([lastValue, element.value])
  }

  return (
    <StyledInput
      {...rest}
      value={localValue}
      maxLength={maxLength}
      className={`enter-store-id-input ${className || ''}`}
      placeholder={intl.formatMessage({ id: 'enterMenuInput.enterMenuId' })}
      onFocus={e => onFocus && onFocus(e)}
      onKeyPress={e => onKeyPress && onKeyPress(e)}
      onChange={e => changeValue(e)}
    />
  )
}

EnterStoreIdInput.defaultProps = {
  type: 'text',
  pattern: '[A-Za-z0-9]{5}',
  maxLength: 5
}

EnterStoreIdInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default EnterStoreIdInput
