import { CircleIcon } from './CircleIcons'
import styled from 'styled-components/macro'
import { theme } from 'styled-tools'
const CircleIconButton = styled(CircleIcon).attrs({
  width: '2.5rem',
  height: '2.5rem'
})`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: ${theme('white')};
  color: ${theme('black')};
  border: 0;
  &:hover {
    background: white;
  }
`

export default CircleIconButton
