import React from 'react'
import loadable from '@loadable/component'
import * as Sentry from '@sentry/browser'
import { withRouter } from 'react-router-dom'
import Favicon from 'react-favicon'
import ScrollToTop from '../../common/ScrollToTop'
import 'animate.css/animate.min.css'
import Footer from '../Footer/Footer'
import FaviconImage from '../../../assets/images/zerotouch_z.png'
import { isMobile } from 'react-device-detect'
import OverlayLoader from '../../shared/OverlayLoader/OverlayLoader'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import * as actionsCookies from '../../../actions/cookies'
import * as actionsRequests from '../../../actions/requests'
import * as actionsNotification from '../../../actions/notification'
import * as actionsTerms from '../../../actions/terms'
import { ThemeProvider } from 'styled-components/macro'
import { defaultTheme } from '../../../ui'
import termsService from '../../../api/services/termsService'
import gdprService from '../../../api/services/gdprCookiesService'
import { Content } from './DeafultLayoutStyledComponents'
import PropTypes from 'prop-types'

const CookiesBox = loadable(() => import('../CookiesBox/CookiesBox'))

class DefaultLayout extends React.PureComponent {
  static propTypes = {
    actionsCookies: PropTypes.object.isRequired,
    actionsTerms: PropTypes.object.isRequired,
    actionsRequests: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    cookies: PropTypes.object.isRequired,
    requests: PropTypes.number.isRequired,
    isFooterVisible: PropTypes.bool,
    animateFooter: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    tTitle: PropTypes.string
  }

  state = {
    dynamicBackground: 'transparent',
    source: 'HAUSMART'
  }

  componentDidMount() {
    termsService
      .getVersion()
      .then(version => {
        const gdpr = gdprService.get()
        let accepted = false
        if (gdpr) {
          accepted = gdpr.isAccepted && gdpr.domain === window.location.hostname && gdpr.version === version.latest
        }
        this.props.actionsCookies.updateState(accepted, version.latest)
        this.props.actionsTerms.setLatestVersion(version)
        return accepted
      })
      .catch(err => {
        Sentry.captureException(err)
        return true
      })
  }

  acceptCookie() {
    this.props.actionsCookies.accept()
  }

  disagreeCookie = () => {
    this.props.actionsCookies.disagree()
  }

  changeBackgroundColor = color => {
    this.setState({ dynamicBackground: color })
  }

  onTimeoutLoader = () => {
    this.props.actionsRequests.clearRequestCount()
  }

  setSource = source => {
    this.setState({ source })
  }

  render() {
    const { history, requests, children, isFooterVisible, animateFooter } = this.props
    const { dynamicBackground } = this.state
    const pathname = history.location.pathname
    const isMenuPage = pathname.split('/')[1] === 'menu' && pathname.indexOf('error') < 0

    return (
      <ThemeProvider theme={defaultTheme}>
        <ScrollToTop>
          {!this.props.cookies.accepted && !isMobile && (
            <CookiesBox onAccept={() => this.acceptCookie()} onDisagree={this.disagreeCookie} />
          )}
          <Favicon url={FaviconImage} />
          {requests > 0 && <OverlayLoader onTimeout={this.onTimeoutLoader} />}

          <div style={isMenuPage ? { backgroundColor: dynamicBackground } : {}}>
            <Content id="main-content">
              {React.cloneElement(children, {
                setSource: this.setSource,
                changeBackgroundColor: this.changeBackgroundColor,
                ...this.props
              })}
            </Content>
            {isFooterVisible && (
              <Footer animate={animateFooter} history={history} isZeroTouch={this.state.source === 'ZEROTOUCH'} />
            )}
          </div>
        </ScrollToTop>
      </ThemeProvider>
    )
  }
}

DefaultLayout.defaultProps = {
  thinBanner: false,
  isFooterVisible: true,
  animateFooter: true
}

// TODO: HOT FIX. It's retarded but it will work. need to revise.
const mapStateToProps = state => {
  return {
    requests: state.requests,
    cookies: state.cookies,
    cart: state.cart
  }
}

const mapDispatchToProps = dispatch => ({
  actionsCookies: bindActionCreators(actionsCookies, dispatch),
  actionsRequests: bindActionCreators(actionsRequests, dispatch),
  actionsNotification: bindActionCreators(actionsNotification, dispatch),
  actionsTerms: bindActionCreators(actionsTerms, dispatch)
})

const ConnectedDefaultLayout = connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)

export default withRouter(ConnectedDefaultLayout)
export { DefaultLayout, ConnectedDefaultLayout }
