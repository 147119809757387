import EventsTracker from '../helpers/eventsTracker'
import preparedCartService from '../api/services/preparedCartService'
import Product from '../api/models/Product'

const eventsTracker = new EventsTracker()

export interface SET_ID {
  type: 'SET_ID',
  shortId: string,
  storeName: string
}
export interface ADD {
  type: 'ADD',
  product: Product,
  itemNumber?: number
}
export interface PRODUCT_QUANTITY_CHANGED {
  type: 'PRODUCT_QUANTITY_CHANGED',
  productId: string,
  newStock: number
}
export interface CLEAR_PRODUCT {
  type: 'CLEAR_PRODUCT',
  productId: string
}
export interface REMOVE {
  type: 'REMOVE',
  product: Product,
  itemNumber?: number
}
export interface CLEAR_CART {
  type: 'CLEAR_CART',
}

export interface UPDATE_CHOICES {
  type: 'UPDATE_CHOICES',
  productId: string,
  choices: string[][]
}

export interface UPDATE_COMMENTS {
  type: 'UPDATE_COMMENTS',
  productId: string,
  comments: string
}

const setId = (shortId: string, storeName: string) => {
  return function (dispatch: (vals: SET_ID) => void) {
    dispatch({
      type: 'SET_ID',
      shortId: shortId.toUpperCase(),
      storeName
    })
  }
}

const addProduct = (product: Product, itemNumber?: number) => {
  return function (dispatch: (func: any) => void) {
    dispatch({
      type: 'ADD',
      product,
      itemNumber
    })
    eventsTracker.addToCart(product)
  }
}

const changeProductQuantity = (productId: string, newStock: number) => {
  return {
    type: 'PRODUCT_QUANTITY_CHANGED',
    productId,
    newStock
  }
}

const clearProduct = (productId: string) => {
  return {
    type: 'CLEAR_PRODUCT',
    productId
  }
}

const removeProduct = (product: Product, itemNumber?: number) => {
  return {
    type: 'REMOVE',
    product,
    itemNumber
  }
}

const clearCart = () => {
  return {
    type: 'CLEAR_CART'
  }
}

const fillCart = (filledCart: Product[]) => {
  return (dispatch: (func: any) => void) => {
    dispatch(clearCart())
    filledCart.forEach(item => {
      dispatch(addProduct(item))
      item._id && dispatch(changeProductQuantity(item._id, item.count))
    })
  }
}

const sendCartToEmail = (email: string, name: string) => (dispatch: (func: any) => void, getState: () => any) => {
  const { cart, activeStore } = getState()
  return preparedCartService.sendCart(
    activeStore._id,
    cart.products,
    email,
    name
  )
}

const updateSubOptionChoices = (productId: string, choices: string[][]) => {
  return {
    type: 'UPDATE_CHOICES',
    productId,
    choices
  }
}

const updateComments = (productId: string, comments: string) => ({
  type: 'UPDATE_COMMENTS',
  productId,
  comments
})

export {
  setId,
  addProduct,
  removeProduct,
  clearCart,
  clearProduct,
  changeProductQuantity,
  sendCartToEmail,
  fillCart,
  updateSubOptionChoices,
  updateComments
}
