// production config

export default {
  apiBaseUrl: 'https://api.hausmart.com',
  graphqlBaseUrl: 'https://api.hausmart.com/v3/graphql',
  wsBaseUrl: 'wss://api.hausmart.com',
  demoShop: 'DEMO1',
  googleSiteVerification: 'SBEQZFkkKY-f1SNVSIRfCmc54xpTFYR7-I4q-zxnbKs',
  privacyPolicyUrl: '/terms/4/Privacy-Policy',
  paypal: {
    scriptSrc: 'https://www.paypal.com/sdk/js',
    environment: 'production',
    clientId: 'AeHvO7dLYAlLLnkZWxCTvHgSBMoFRn-bu1Wy9kjEXZVb8wYZPRpEykxDhLQ0WjgUPQz_MeF1e1FnH4mT'
  },
  firebase: {
    apiKey: 'AIzaSyBvCpsSkco92Y1h2IM8pW7VMh1YBF10PR8',
    authDomain: 'prod-instance-hm.firebaseapp.com',
    databaseURL: 'https://prod-instance-hm.firebaseio.com',
    projectId: 'prod-instance-hm',
    storageBucket: 'prod-instance-hm.appspot.com',
    messagingSenderId: '208737725243'
  }
}
