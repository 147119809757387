import React, { useState, useRef } from 'react'
import ReactGA from 'react-ga'
import { PopoverBody } from 'reactstrap'
import storeService from '../../../api/services/storeService'
import { FormattedMessage, useIntl } from 'react-intl'
import { Wrapper, Form, Button, SubmitButton, Popover, EnterStoreIdInput } from './EnterStoreIdStyledComponents'
import { Notification } from 'react-notification-system'
import { History } from 'history'

interface Props {
  showNotification: (notification: Notification) => void
  toggleGeolocationModal: () => void
  history: History
}

function EnterStoreId({ toggleGeolocationModal, showNotification, history }: Props) {
  const intl = useIntl()
  const [storeId, setStoreId] = useState('')
  const [popoverOpen, setPopoverOpen] = useState(false)
  const formEl: React.RefObject<HTMLFormElement> = useRef(null)

  const handleChangeID = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.toUpperCase().replace(/[^A-Za-z0-9+]+/, '')
    const storeId = event.target.value
    setStoreId(storeId)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.charCode === 13) {
      submitID(event)
    }
  }

  const submitID = async (event: React.FormEvent) => {
    event.preventDefault()

    ReactGA.event({
      category: 'enter_store',
      action: 'submit_id',
      label: storeId
    })

    if (!validateID(storeId)) {
      return
    }

    try {
      const resp = await storeService.getStore(storeId)
      history.push(`/menu/${storeId}`, {
        storeId: storeId,
        freshStore: true,
        claimed: resp.is_claimed
      })
    } catch (err) {
      if (err.isNotGeo()) {
        setPopoverOpen(true)

        showNotification({
          title: intl.formatMessage({ id: 'error.geolocation.title' }),
          message: intl.formatMessage({ id: 'error.geolocation.message' }),
          level: 'error'
        })
      } else if (err.isNotFound()) {
        try {
          await storeService.getClaimStatus(storeId)
          history.push(`/link?storeId=${storeId}`)
        } catch (e) {
          showNotification({
            title: intl.formatMessage({ id: 'general.error.menuId' }),
            message: intl.formatMessage({ id: 'error.geolocation.any' }),
            level: 'error'
          })
        }
      } else if (err.isNotInLocation()) {
        showNotification({
          title: err.message,
          message: intl.formatMessage({ id: 'error.geolocation.any' }),
          level: 'error'
        })
      } else if (err.isPrivate()) {
        history.push(`/menu/${storeId}`, {
          error: {
            type: err.type,
            message: err.message,
            isNotGeo: false,
            isNotFound: false
          },
          isPrivate: true
        })
      } else {
        showNotification({
          title: intl.formatMessage({ id: 'storeErrorPage.title' }),
          message: JSON.stringify(err),
          level: 'error'
        })
      }
    }
  }

  const validateID = (storeId: string) => {
    if (!storeId || storeId.length !== 5) {
      showNotification({
        title: intl.formatMessage({ id: 'error.incorrectId.title' }),
        message: intl.formatMessage({ id: 'error.incorrectId.message' }),
        level: 'error'
      })
      return false
    } else if (!formEl.current?.checkValidity()) {
      showNotification({
        title: intl.formatMessage({ id: 'error.incorrectId.title' }),
        message: intl.formatMessage({
          id: 'error.incorrectId.message.detailed'
        }),
        level: 'error'
      })
      return false
    }
    return true
  }

  return (
    <Wrapper className="enter-store">
      <Form ref={formEl}>
        <EnterStoreIdInput
          id="typeIdInput"
          aria-label="Store ID"
          onKeyPress={handleKeyPress}
          onChange={handleChangeID}
        />
        <SubmitButton id="submitId" type="submit" color="secondary" onClick={submitID}>
          <FormattedMessage id="enterStoreId.go" />
        </SubmitButton>
        <Popover placement="top" isOpen={popoverOpen} target="submitId">
          <div className="popover-header">
            <span className="text-dark">
              <FormattedMessage id="enterStoreId.locationRequired" />
            </span>
            <Button type="button" className="close" aria-label="Close" onClick={() => setPopoverOpen(!popoverOpen)}>
              <span aria-hidden="true">×</span>
            </Button>
          </div>
          <PopoverBody>
            <p>
              <FormattedMessage id="enterStoreId.needLocationAccess" />
            </p>
            <button className="text-primary" onClick={toggleGeolocationModal}>
              <FormattedMessage id="enterStoreId.howEnableLocation" />
            </button>
          </PopoverBody>
        </Popover>
      </Form>
    </Wrapper>
  )
}

export default EnterStoreId
