import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import './scss/style.scss'
import { BrowserRouter } from 'react-router-dom'
import { ThroughProvider } from 'react-through'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'
// Import i18n messages
import { translationMessages } from './i18n'
import store from './store'
import LanguageProvider from './components/common/LanguageProvider'
import langInit from './helpers/lang'
import apolloClient from './api/apolloClient'
import * as Sentry from '@sentry/browser'
import './polyfills'

require('./analytics.js')
require('intersection-observer')

Sentry.init({
  dsn: 'https://1d994d415f284e2b8f649d75f946e88e@sentry.io/1435625',
  blacklistUrls: [/localhost/]
})

const MOUNT_NODE = document.getElementById('react-app')

langInit()

ReactDOM.render(
  <Provider store={store}>
    <LanguageProvider messages={translationMessages}>
      <BrowserRouter>
        <ThroughProvider>
          <ApolloProvider client={apolloClient}>
            <ApolloHooksProvider client={apolloClient}>
              <App />
            </ApolloHooksProvider>
          </ApolloProvider>
        </ThroughProvider>
      </BrowserRouter>
    </LanguageProvider>
  </Provider>,
  MOUNT_NODE
)
