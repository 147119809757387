import Product from './Product'
import find from 'lodash/find'
import { isArray, toNumber } from 'lodash'
import uniqBy from 'lodash/uniqBy'
import stripHtml from '../../helpers/stripHtml'
import { Theme } from '../../ui/types'
import { defaultTheme } from 'ui'

declare global {
  interface Window {
    prerenderCloudIsServerSideRendering: any
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
  }
}

export type Category = {
  name: string
  products: Product[]
  sortOrder?: number
}

export type Geometry = {
  latitude: number
  longitude: number
}

export type Schedule = {
  type: 'shipping' | 'pickup'
  timezone: string
  timezone_name: string
  times: string[]
}

export type ServeCharacters = 'alphanumerical' | 'numerical' | 'letters'

interface Social {
  name: string
  url: string
}

interface SocialImage {
  image_id: string
  url: string
}

interface CampaignData {
  code: string
}

interface FeatureHints {
  is_open_for_orders_hint_dismissed: boolean
}

interface Store {
  _id: string
  shortId: string
  ownerId: string
  name: string
  ownerName: string
  currency: string
  message: string
  owner: string
  allProductsVisible: boolean
  formatted_address: string
  products: Product[]
  cover_photo: string
  cover_photo_social: string
  ownerPicture: string
  is_marketing: boolean
  theme: Theme
  display_low_stock: boolean
  is_stock_enabled: boolean
  is_indexable: boolean
  is_private: boolean
  is_list_view?: boolean
  is_chat_enabled: boolean
  is_shipping_enabled: boolean
  images_social: SocialImage[]
  source: StoreSource
  about_html: string
  facebook_url: string
  instagram_url: string
  linkedin_url: string
  twitter_url: string
  pinterest_url: string
  outside_store_range: boolean
  geometry: Geometry
  is_sub_options_enabled: boolean
  influencer_campaign: CampaignData
  is_claimed: boolean
  paypal_merchant_id: string
  is_service_fee_enabled: boolean
  service_fee_percentage: number
  is_shipping_open: boolean
  is_pickup_open: boolean
  schedules: Schedule[]
  is_serve_enabled: boolean
  serve_identifier: string
  serve_characters: ServeCharacters
  is_table_pickup_enabled: boolean
  is_table_pickup_notifications_enabled: boolean
  feature_hints: FeatureHints
}

interface StoreProps {
  _id?: string
  shortId?: string
  ownerId?: string
  name?: string
  ownerName?: string
  currency?: string
  message?: string
  owner?: string
  allProductsVisible?: boolean
  formatted_address?: string
  products?: Product[]
  cover_photo?: string
  cover_photo_social?: string
  ownerPicture?: string
  is_marketing?: boolean
  theme?: any
  display_low_stock?: boolean
  is_stock_enabled?: boolean
  is_indexable?: boolean
  is_private?: boolean
  is_list_view?: boolean
  is_chat_enabled?: boolean
  is_shipping_enabled?: boolean
  images_social?: SocialImage[]
  source?: StoreSource
  about_html?: string
  facebook_url?: string
  instagram_url?: string
  linkedin_url?: string
  twitter_url?: string
  pinterest_url?: string
  outside_store_range?: boolean
  geometry?: {
    latitude: number
    longitude: number
  }
  is_sub_options_enabled?: boolean
  influencer_campaign?: CampaignData
  is_claimed?: boolean
  paypal_merchant_id?: string
  is_service_fee_enabled?: boolean
  service_fee_percentage?: number
  is_shipping_open?: boolean
  is_pickup_open?: boolean
  schedules?: Schedule[]
  is_serve_enabled?: boolean
  serve_identifier?: string
  serve_characters?: ServeCharacters
  is_table_pickup_enabled?: boolean
  is_table_pickup_notifications_enabled?: boolean
  feature_hints?: FeatureHints
}

class Store {
  constructor({
    _id = '',
    shortId = '',
    ownerId = '',
    name = '',
    ownerName = '',
    currency = '',
    message = '',
    owner = '',
    allProductsVisible = true,
    formatted_address = '',
    products = [],
    cover_photo = '',
    cover_photo_social = '',
    ownerPicture = '',
    is_marketing = false,
    theme = defaultTheme,
    display_low_stock = false,
    is_stock_enabled = false,
    is_indexable = false,
    is_list_view = false,
    is_private = false,
    is_chat_enabled = false,
    is_shipping_enabled = false,
    images_social = [],
    source = 'HAUSMART',
    about_html = '',
    facebook_url = undefined,
    instagram_url = undefined,
    linkedin_url = undefined,
    twitter_url = undefined,
    pinterest_url = undefined,
    outside_store_range = false,
    geometry = {
      latitude: 51.1,
      longitude: 45.3
    },
    is_sub_options_enabled = false,
    influencer_campaign = undefined,
    is_claimed = false,
    paypal_merchant_id = '',
    is_service_fee_enabled = true,
    service_fee_percentage = 20,
    is_shipping_open = true,
    is_pickup_open = true,
    schedules = [],
    is_serve_enabled = true,
    serve_identifier = 'Table #',
    serve_characters = 'numerical',
    is_table_pickup_enabled = true,
    is_table_pickup_notifications_enabled = false,
    feature_hints = {
      is_open_for_orders_hint_dismissed: false
    }
  }: StoreProps = {}) {
    this._id = _id
    this.shortId = shortId
    this.ownerId = ownerId
    this.name = name
    this.ownerName = ownerName
    this.currency = currency
    this.message = message
    this.owner = owner
    this.allProductsVisible = allProductsVisible
    this.formatted_address = formatted_address
    this.cover_photo = cover_photo
    this.cover_photo_social = cover_photo_social
    this.ownerPicture = ownerPicture
    this.is_marketing = is_marketing
    this.theme = theme.values ? theme.values : theme
    this.display_low_stock = display_low_stock
    this.is_stock_enabled = is_stock_enabled
    this.is_indexable = is_indexable
    this.is_list_view = is_list_view
    this.is_private = is_private
    this.is_chat_enabled = is_chat_enabled
    this.is_shipping_enabled = is_shipping_enabled
    this.images_social = images_social
    this.products = products
    this.source = source
    this.about_html = about_html
    facebook_url && (this.facebook_url = facebook_url)
    instagram_url && (this.instagram_url = instagram_url)
    linkedin_url && (this.linkedin_url = linkedin_url)
    twitter_url && (this.twitter_url = twitter_url)
    pinterest_url && (this.pinterest_url = pinterest_url)
    this.outside_store_range = outside_store_range
    this.geometry = geometry
    this.is_sub_options_enabled = is_sub_options_enabled
    influencer_campaign && (this.influencer_campaign = influencer_campaign)
    this.is_claimed = is_claimed
    this.paypal_merchant_id = paypal_merchant_id
    this.is_service_fee_enabled = is_service_fee_enabled
    this.service_fee_percentage = service_fee_percentage
    this.is_shipping_open = is_shipping_open
    this.is_pickup_open = is_pickup_open
    this.schedules = schedules
    this.is_serve_enabled = is_serve_enabled
    this.serve_identifier = serve_identifier
    this.serve_characters = serve_characters
    this.is_table_pickup_enabled = is_table_pickup_enabled
    this.is_table_pickup_notifications_enabled = is_table_pickup_notifications_enabled
    this.feature_hints = feature_hints

    if (products.length >= 1) {
      this.products = products.map(product => {
        return new Product({ ...product })
      })
    }
  }

  getDescription() {
    return this.message
  }

  getShortId() {
    return this.shortId
  }

  isChatEnabled() {
    return this.is_chat_enabled && !window.prerenderCloudIsServerSideRendering
  }

  getOwnerId() {
    return this.ownerId
  }

  isInsideGeo() {
    return this.outside_store_range !== undefined ? !this.outside_store_range : true
  }

  getName() {
    return this.name
  }

  getSourceName() {
    return 'ZeroTouch'
  }

  getBanner() {
    return this.cover_photo
  }

  getBackgroundColor() {
    return this.theme?.storeBackgroundColor || defaultTheme.storeBackgroundColor
  }

  findProductById(productId: string) {
    return this.getProducts().find(item => item._id === productId)
  }

  getProducts() {
    return this.products
  }

  shippingEnabled() {
    return this.is_shipping_enabled
  }

  changeProductStock(productId: string, newStock: number) {
    const product = this.products.find(item => item.getId() === productId)
    product && product.setStock(newStock)
  }

  changeProductStockEnabled(productId: string, status: boolean) {
    const product = this.products.find(item => item.getId() === productId)
    product && product.setStockEnabled(status)
  }

  getOwnerPicture() {
    return this.ownerPicture
  }

  getSocialPhoto(imageId: string | null = null) {
    if (imageId && isArray(this.images_social)) {
      const socialImage = this.images_social.find(image => image.image_id === imageId)
      return socialImage ? socialImage.url : this.cover_photo_social
    }
    return this.cover_photo_social
  }

  getSocialPhotos() {
    return uniqBy(this.images_social, 'url')
  }

  isMarketingStore() {
    return this.is_marketing
  }

  countCategories() {
    return this.getProducts().filter(product => !!product.tag).length
  }

  getSortedCategories() {
    const categories: Category[] = [{ name: 'Other', products: [], sortOrder: Infinity }]

    this.getProducts().forEach(product => {
      const tag = product.tag
      if (tag) {
        const foundCategory = find(categories, category => category.name === tag.name)
        if (foundCategory) {
          foundCategory.products.push(product)
        } else {
          categories.push({
            name: tag.name,
            sortOrder: tag.sortOrder === undefined ? 9999 : tag.sortOrder,
            products: [product]
          })
        }
      } else {
        categories[0].products.push(product)
      }
    })

    return categories
      .filter((category: Category) => category.products.length)
      .sort((a, b) => toNumber(a.sortOrder) - toNumber(b.sortOrder))
  }

  getOwnUrl() {
    return window.location.protocol + '//' + window.location.host + '/menu/' + this.shortId.toUpperCase()
  }

  getOwnerName() {
    return this.ownerName
  }

  getAboutHTML() {
    return this.about_html
  }

  getAboutText() {
    return stripHtml(this.about_html)
  }

  getCampaingTag() {
    return this.influencer_campaign || {}
  }

  getOwnerSocial() {
    const social = []
    if (this.facebook_url) {
      social.push({ name: 'facebook', url: this.facebook_url })
    }
    if (this.instagram_url) {
      social.push({ name: 'instagram', url: this.instagram_url })
    }
    if (this.linkedin_url) {
      social.push({ name: 'linkedin', url: this.linkedin_url })
    }
    if (this.twitter_url) {
      social.push({ name: 'twitter', url: this.twitter_url })
    }
    if (this.pinterest_url) {
      social.push({ name: 'pinterest', url: this.pinterest_url })
    }
    return social
  }

  getOwnerInstagram() {
    if (!this.instagram_url) {
      return
    }
    const instagram: Social = {
      url: this.instagram_url,
      name: '@' + this.instagram_url.split('/').reverse()[1]
    }
    return instagram
  }

  getMerchantId() {
    return this.paypal_merchant_id
  }

  getServiceFeePercentage() {
    return this.is_service_fee_enabled ? this.service_fee_percentage : 0
  }

  getSchedules() {
    return this.schedules
  }

  isServiceFeeEnabled() {
    return this.is_service_fee_enabled
  }

  isPrivate() {
    return this.is_private
  }

  isIndexable() {
    return this.is_indexable
  }

  isListViewEnabled() {
    return this.is_list_view
  }

  showLowStock() {
    return this.display_low_stock
  }

  isStockEnabled() {
    return this.is_stock_enabled
  }

  setStockEnabled(status: boolean) {
    this.is_stock_enabled = status
  }

  setShippingEnabled(status: boolean) {
    this.is_shipping_enabled = status
  }

  changeProductShippingEnabled(productId: string, status: boolean) {
    const product = this.products.find(item => item.getId() === productId)
    product && product.setShippingEnabled(status)
  }

  isSubOptionsEnabled() {
    return this.is_sub_options_enabled
  }

  isShippingOpen() {
    return this.is_shipping_open
  }

  isPickupOpen() {
    return this.is_pickup_open
  }

  isServeToTableEnabled() {
    return this.is_serve_enabled
  }

  getServeIdentifier() {
    return this.serve_identifier
  }

  getServeCharacters(): ServeCharacters {
    return this.serve_characters
  }

  isTablePickupEnabled() {
    return this.is_table_pickup_enabled
  }

  isTablePickupNotificationsEnabled() {
    return this.is_table_pickup_notifications_enabled
  }

  isOpenForOrders() {
    return this.feature_hints.is_open_for_orders_hint_dismissed
  }
}

export default Store
