import { Input } from 'reactstrap'
import styled from 'styled-components/macro'
import { theme } from 'styled-tools'

const StyledInput = styled(Input)`
  &.form-control {
    padding: 0.4rem 0.6rem;
    font-size: 1.4rem;
    line-height: 1.6;
    height: 3rem;
    border-radius: 0.35rem;
    font-weight: 800;
    text-align: center;
    width: 60%;
    border: 1px solid ${theme('black')};
    text-transform: uppercase;
    &:focus {
      background-color: white;
    }
    &::placeholder {
      color: #1e1b1c;
      text-align: center;
      text-transform: none;
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
`

export { StyledInput }
